.flip > section > span {
  padding: 0rem 0rem;
  background-color: #16151a;
}

.flip > section > span > span {
  font-size: 2.375rem !important;
}

.flip > section > span > span > span {
  font-size: 2.375rem !important;
}
