.card {
  position: absolute !important;
  cursor: pointer !important;
  transition: 500ms !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  max-width: 565px !important;
  z-index: 5 !important;
}

.card--active {
  opacity: 1 !important;
  z-index: 5 !important;
}

.card--left {
  transform: translateX(-50%) scale(0.9) !important;
  transition: 500ms !important;
  z-index: 0 !important;
  opacity: 0.9 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.card--right {
  transform: translateX(50%) scale(0.9) !important;
  transition: 500ms !important;
  z-index: 0 !important;
  opacity: 0.9 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.card--right > div:first-child > div:first-child {
  flex-direction: row-reverse;
}

.card--right > div:first-child > div:nth-child(2) > .MuiBox-root {
  align-self: flex-end !important;
  margin-left: 50px;
}

.card--left > div:first-child > div:nth-child(2) > .MuiBox-root {
  align-self: flex-start !important;
  margin-right: 50px;
}

@media screen and (max-width: 1199px) {
  .card--left {
    transform: translateX(-20%) scale(0.9) !important;
  }

  .card--right {
    transform: translateX(20%) scale(0.9) !important;
  }
}

@media screen and (max-width: 899px) {
  .card--left {
    transform: translateX(0) scale(0.9) !important;
  }

  .card--right {
    transform: translateX(0) scale(0.9) !important;
  }
}
